<template>
    <v-container fluid fill-height>
        <img class="logout" src="../assets/images/logout.svg" alt="Logout"/>
    </v-container>
</template>

<script>
export default {
    name: 'Logout',
    data: () => ({
        timeout: null
    }),
    mounted() {
        this.$router.push('/');
        setTimeout(() => {

            this.$keycloak.logout();
        }, 500);
    },
}
</script>

<style lang="scss" scoped>
.logout {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
</style>
